import { Button } from 'antd';
import Router, { useRouter } from 'next/router';
import useTrans from '../hooks/useTrans';
import Link from 'next/link';
const Custom404 = () => {
  const trans = useTrans();
  return (
    <section className="p-not-found">
      <div className="p-not-found__alert">
        <div className="l-container">
          <div className="p-not-found__alert__image">
            <img src="/assets/img/common/img_not-found.png" alt="" />
          </div>
          <div className="p-not-found__alert__desc">
            <p>{trans.error.title}</p>
          </div>
          <div className="p-not-found__alert__handle">
            <Link href='/'>
              <a style={{ cursor: 'pointer' }} className="c-btn c-title--center">{trans.error.button}</a>
            </Link>
          </div>
        </div>
      </div>
    </section>

  );
};



export default Custom404;
